const Colors = {
  primary: "#0EA5EA",
  secondary: "#0076BF",
  secondary1: "#0BD1D1",
  backgroundColor1: "#0F172A",
  backgroundColor2: "#0C1427",
  white: "#FFFFFF",
  black: "#000000",
  textColor1: "#B9E0F2",
  textColor2: "#7F92B0",
  contactGradient1: "linear-gradient(0deg, rgba(152,161,174,0) 6%, rgba(11,209,209,0.08) 100%)",
  contactGradient2: "linear-gradient(270deg, rgba(11,209,209,1) 32%, rgba(14,165,234,1) 84%)"
}

export default Colors;