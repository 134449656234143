import { Box, Button, CardMedia, Grid, TextField, ThemeProvider, Typography, createTheme } from '@mui/material'
import React, { useState } from 'react'
import background from '../../../assests/images/mayaccountbg.png'
import Colors from '../../../assests/style'
import { useForm } from 'react-hook-form'
import { SecondaryButton } from '../../../components/Buttons'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Images } from '../../../assests'

const ContactUs = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [successMessage, setSuccessMessage] = useState(null);

    const theme = createTheme({
        typography: {
            fontFamily: 'Poppins, sans-serif'// You can change this to your preferred font
        },
        components: {
            MuiStepLabel: {
                styleOverrides: {
                    label: {
                        color: 'rgba(255, 255, 255, 0.7)',
                        fontFamily: 'Poppins, sans-serif',
                        '&.Mui-active': {
                            color: '#ffffff',
                        },
                        '&.Mui-completed': {
                            color: '#ffffff',
                        },
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& .MuiInputBase-root': {
                            color: 'white', // Text color,
                            fontFamily: 'Poppins, sans-serif'
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white', // Label color
                        },
                        '& .MuiInput-underline:before': {
                            borderBottomColor: 'white', // Underline color when unfocused
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: 'white', // Underline color when focused
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'white', // Border color when unfocused
                            },
                            '&:hover fieldset': {
                                borderColor: 'white', // Border color on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'white', // Border color when focused
                            },
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        color: 'white', // Text color for input
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: 'white', // Border color for input
                    },
                    root: {
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: 'white', // Text color for label
                        borderColor: 'white',
                        '&.Mui-focused': {
                            color: 'white',
                        },
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        color: 'black', // Text color for menu items
                        backgroundColor: 'white', // Background color for menu items
                        '&.Mui-selected': {
                            backgroundColor: 'rgba(255, 255, 255, 0.2)', // Background color for selected item
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)', // Background color for hover
                        },
                    },
                },
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: 'white', // Color for dropdown arrow
                    },
                },
            },
        },
        palette: {
            primary: {
                main: '#1976d2',
            },
            background: {
                default: '#333333',
            },
        },

    });

    const onSubmit = (data) => {
        // Send the data to your backend here
        console.log(data);
        setSuccessMessage('Thank you for your message!');
        // Reset the form
        setTimeout(() => {
            setSuccessMessage(null);
        }, 3000);
    };
    return (
        <div>
            <Box
                sx={{
                    backgroundImage: `url("${Images.contactBg}")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: '300px', // Adjust height as needed
                    display: 'flex',
                    alignItems: 'center', justifyContent: 'center',
                    position: "relative"
                }}
            >
                {/* <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: Colors.backgroundColor1
                    }}
                /> */}
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <p className='banner-text heading-font' style={{ fontSize: '35px', textAlign: 'center', fontFamily: "Noto Sans, sans-serif", }}> Contact Us  </p>
                    {/* <p style={{ fontSize: '25px', textAlign: 'center', color: 'white', fontFamily: "Noto Sans, sans-serif", }}>Homepage || <span style={{ color: Colors.secondary }}>Contact Us</span></p> */}
                </Box>
            </Box>
            <Box component={"section"} sx={{ py: { md: "96px" } }}>
                {/* <Typography sx={{ color: Colors.secondary, textAlign: 'center', fontSize: '50px', fontFamily: "Noto Sans, sans-serif", fontWeight: 'bold', mt: '100px' }}>
                    Get in Touch Now!
                </Typography> */}

                {/* <p style={{ color: Colors.textColor1, fontSize: '17.5px', padding: 4, textAlign: 'center' }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.


                </p> */}
                <Grid container justifyContent={"center"} rowGap={"50px"}>
                    <Grid item md={10} sm={12} xs={12}>
                        <Grid
                            container
                            sx={{
                                background: Colors.contactGradient1,
                                borderRadius: "20px"
                            }}
                        >
                            <Grid item md={6}>
                                <Box>
                                    <CardMedia
                                        component={"img"}
                                        src={Images.contactImg1}
                                        sx={{
                                            height: "400px",
                                            width: "400px",
                                            objectFit: "contain"
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={6} sx={{ display: "flex" }}>
                                <Box
                                    sx={{
                                        p: 4,
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "flex-start",
                                        gap: 2
                                    }}
                                >
                                    <Typography
                                        className='heading-font'
                                        sx={{
                                            fontSize: "48px",
                                            fontWeight: 700,
                                            color: Colors.secondary1,
                                        }}
                                    >
                                        Need help with <br />
                                        Print Me Up?
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            color: Colors.secondary1
                                        }}
                                    >
                                        Report issues and ask questions here!
                                    </Typography>
                                    <Button
                                        sx={{
                                            background: Colors.secondary1,
                                            color: Colors.backgroundColor1,
                                            textTransform: "capitalize",
                                            p: "15px 25px",
                                            fontSize: "24px",
                                            fontWeight: 500,
                                            borderRadius: "5px",
                                            border: "1px solid transparent",
                                            ":hover": {
                                                border: `1px solid ${Colors.secondary1}`,
                                                color: Colors.secondary1,
                                            }
                                        }}
                                    >
                                        Get Help
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={10} sm={12} xs={12}>
                        <Grid
                            container
                            sx={{
                                background: Colors.contactGradient2,
                                borderRadius: "20px",
                            }}
                        >
                            <Grid item md={6} sx={{ display: "flex" }}>
                                <Box
                                    sx={{
                                        p: 4,
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "flex-start",
                                        gap: 2
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "48px",
                                            fontWeight: 700,
                                            color: Colors.backgroundColor1,
                                        }}
                                    >
                                        Print Me Up is Always<br />
                                        Ready to Help!
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            color: Colors.backgroundColor1
                                        }}
                                    >
                                        Our Support is available 24/7 to help
                                    </Typography>
                                    <Button
                                        sx={{
                                            background: Colors.backgroundColor1,
                                            color: Colors.white,
                                            textTransform: "capitalize",
                                            p: "15px 25px",
                                            fontSize: "24px",
                                            fontWeight: 500,
                                            borderRadius: "5px",
                                            border: "1px solid transparent",
                                            ":hover": {
                                                border: `1px solid ${Colors.backgroundColor1}`,
                                                color: Colors.backgroundColor1,
                                            }
                                        }}
                                    >
                                        Chat Now
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box>
                                    <CardMedia
                                        component={"img"}
                                        src={Images.contactImg2}
                                        sx={{
                                            height: "400px",
                                            width: "516px",
                                            objectFit: "contain",
                                            position: "relative",
                                            float: "right",
                                            bottom: "-18px"
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Box>
            {/* <ThemeProvider theme={theme}>
                <Box sx={{ width: { xs: '90%', sm: '70%', md: '70%' }, margin: '0 auto' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <Grid container spacing={2} mt={5} mb={5}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Your Name"
                                    {...register('name', { required: true })}
                                    error={!!errors.name}
                                    sx={{ input: { color: 'white' }, label: { color: 'white' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
                                    helperText={errors.name ? 'Name is required' : ''}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Email Address"
                                    {...register('email', {
                                        required: true,
                                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                                    })}
                                    error={!!errors.email}
                                    sx={{
                                        input: { color: 'white' },
                                        label: { color: 'white' },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'white',
                                            '&:hover': {
                                                borderColor: 'white'
                                            }
                                        }
                                    }}
                                    helperText={errors.email ? 'Invalid email address' : ''}
                                    fullWidth
                                />
                            </Grid>


                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Phone Number"
                                    {...register('phone', {
                                        required: true,

                                    })}
                                    type='number'
                                    error={!!errors.phone}
                                    sx={{ input: { color: 'white' }, label: { color: 'white' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
                                    helperText={errors.phone ? 'Invalid phone number' : ''}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>


                                <TextField
                                    label="Subject"
                                    {...register('subject', { required: true })}
                                    error={!!errors.subject}
                                    sx={{ input: { color: 'white' }, label: { color: 'white' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
                                    helperText={errors.subject ? 'Subject is required' : ''}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    label="Message"
                                    {...register('message', { required: true })}
                                    error={!!errors.message}
                                    sx={{ input: { color: 'white' }, label: { color: 'white' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
                                    helperText={errors.message ? 'Message is required' : ''}
                                    multiline
                                    rows={6}
                                    fullWidth
                                />
                            </Grid>
                            {successMessage && (
                            <Grid item xs={12}>
                                <Typography variant="body1" color="green" gutterBottom>
                                    {successMessage}
                                </Typography>
                            </Grid>
                        )}
                            <Grid item xs={12}>
                                <SecondaryButton
                                    type={'submit'}
                                    bgColor={'#0bc1d9'} fullWidth={true} className={'checkout-btn'} iconPosition={'end'} icon={<ArrowForwardIcon sx={{ color: '#0C1427' }} />} p={"15px 40px"} fontWeight={'bold'} color={'#0C1427'} title={'Send Message'} />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </ThemeProvider> */}
        </div>
    )
}

export default ContactUs
