import logo from './logo.svg';
import './App.css';
import Home from './pages/Website/Home/index1';
import Login from './pages/Auth/Login';
import Signup from './pages/Auth/Signup';
import Product from './pages/Website/Product';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import '@fontsource/noto-sans'
import { createTheme, ThemeProvider } from '@mui/material';
import Colors from './assests/style';
import WebLayout from './Layout';
import MyAccount from './pages/Website/MyAccount';
import Gallery from './pages/Website/Gallery';
import ProductFinal from './pages/Website/ProductFinal';
import Order from './pages/Website/Order';
import { AuthContext } from './Context/AuthContext';
import { useEffect, useState } from 'react';
import Checkout from './pages/Website/checkout';
import ContactUs from './pages/Website/ContactUs';
import OrderDetail from './pages/Website/OrderDetail';
import { CartContext } from './Context/CartContext';
import TextShapes from './pages/test';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import history from './services/HistoryService';
import PDFGenerator from './pages/test';
import OrderPlaced from './pages/OrderPlaced';
import Collection from './pages/Website/Collection';
import Cart from './pages/Website/cart';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import TermsAndConditions from './pages/Website/termsandconditions';
import PrivacyPolicy from './pages/Website/privacypolicy';
import ComingSoon from './pages/Website/CominSoon';


const theme = createTheme({
  typography: {
    fontFamily: "Noto Sans, sans-serif",
    h1: {
      fontSize: "72px",
    },
    h2: {
      fontSize: "60px",
    },
    h3: {
      fontSize: "48px",
    },
    h4: {
      fontSize: "36px",
    },
    h5: {
      fontSize: "24px",
    },
    h6: {
      fontSize: "18px",
    },
    subtitle1: {
      fontSize: "16px",
    },
    subtitle2: {
      fontSize: "14px",
      fontWeight: 400,
    },
    caption: {
      fontSize: "12px",
    },
  },
  palette: {
    primary: {
      main: Colors.primary
    }
  }
})
function App() {
  let User = localStorage.getItem('user')
  const [user, setUser] = useState("");
  User = JSON.parse(User)
  const [cart, setCart] = useState("");
  const [cartVisible, setCartVisible] = useState(false);
  const [path, setPath] = useState('')
let url
  const toggleCartVisibility = () => {
    setCartVisible(!cartVisible);
  };
  console.log(user);
  


  useEffect(() => {
    const unlisten = history.listen(({ location }) => {
      console.log(location.pathname, 'Path changed');
      console.log(location.pathname.includes('product'), 'Path changed');
      url=location.pathname
      setPath(location.pathname)
    });
    console.log(User,'user');
    
    setUser(User)
    return () => {
      unlisten();
    };
 
  }, []); // Only on mount

  return (
    <div className="App">
      {/* {!url?.includes('product') && (
        <TawkMessengerReact
          propertyId="673b02674304e3196ae466ce"
          widgetId="1icv82puo"
        />
      )}
   */}
      <ThemeProvider theme={theme}>
        <AuthContext.Provider value={{ user, setUser }}>
          <CartContext.Provider value={{ cart, setCart, cartVisible, toggleCartVisibility }}>
            <HistoryRouter history={history}>
              <Routes>
                {/* <Route
                  path="/order/complete"
                  element={User ? <OrderPlaced /> : <Navigate to="/" />}
                />
                <Route path="/" element={<WebLayout />}>
                  <Route index element={<Home />} />
                  <Route path="collections" element={<Collection />} />
                  <Route path="contact-us" element={<ContactUs />} />
                  <Route path="new" element={<PDFGenerator />} />
                  <Route path="my-account" element={User ? <MyAccount /> : <Navigate to="/" />} />
                  <Route path="order/:id" element={<Order />} />
                  <Route path="cart" element={<Cart />} />
                  <Route path="termsandconditions" element={<TermsAndConditions />} />
                  <Route path="privacypolicy" element={<PrivacyPolicy />} />
                  <Route path="order-detail" element={<OrderDetail />} />
                  <Route path="checkout" element={<Checkout />} />
                  <Route path="gallery" element={<Gallery />} />
                  <Route path="test" element={<TextShapes />} />
                </Route>
                <Route path="login" element={<Login />} />
                <Route path="signup" element={<Signup />} />
                <Route path="product" element={<Product />} />
                <Route path="product-final" element={<ProductFinal />} /> */}
                <Route path="/" element={<ComingSoon />}></Route>
              </Routes>
            </HistoryRouter>
          </CartContext.Provider>
        </AuthContext.Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
