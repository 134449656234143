import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Paper, Step, StepConnector, StepLabel, Stepper, Typography, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Colors from '../../../assests/style';
import moment from 'moment';
import OrderServices from '../../../services/OrderServices';
import CircleIcon from "@mui/icons-material/Circle";
import { deepPurple } from '@mui/material/colors';
import { Images } from '../../../assests';
import { ContextMenuTrigger } from 'react-contextmenu';
import { PDFExport } from '@progress/kendo-react-pdf';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const useStyles = styled({
    blurredBackground: {
        filter: 'blur(12px)', // Apply the blur effect
        transition: 'filter 0.3s ease', // Smooth transition
    },
    popupImage: {
        width: '100%',
        maxWidth: '300px',
        margin: '0 auto',
    },
    popupButton: {
        backgroundColor: '#00a8ff', // Same button color
        color: '#fff',
        marginTop: '20px',
    },
});

const steps = ['Pending', 'Processing', 'Confirmed', 'Delivered'];

const ColorConnector = styled(StepConnector)(({ theme }) => ({
    [`& .${StepConnector.line}`]: {
        borderColor: theme.palette.primary.main,
    },
}));

const ColorStepLabel = styled(StepLabel)(({ theme, active, completed }) => ({
    '& .MuiStepLabel-label': {
        color: active || completed ? ` ${Colors.secondary} !important  ` : '#a1acb1',
    },
    '& .MuiStepIcon-root': {
        color: active || completed ? theme.palette.primary.main : 'white',
    },
}));
const OrderDetail = () => {

    const classes = useStyles();
    const { state } = useLocation()
    const [rotateDirection, setRotateDirection] = useState(false)
    console.log(state);
    const [tshirtPrice, setTshirtPrice] = useState(0)
    console.log(state);
    const [open, setOpen] = useState(false);
    const [allData, setAllData] = useState(null)
    const pdfExportComponentFront = useRef(null);
    const pdfExportComponentBack = useRef(null);
    const elementFront = useRef(null)
    const elementBack = useRef(null)
    const parentRef = useRef(null);
    const divRef = useRef(null);
    const divRefBack = useRef(null);
    const [checked, setChecked] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getPricing = async () => {
        try {
            let params = {

            }
            const { data } = await OrderServices.getPricing(params)
            console.log(data);
            setTshirtPrice(data?.pricing?.standard_price)


        } catch (error) {
            console.log(error);
        } finally {

        }
    }
    const [status, setStatus] = useState(1)
    useEffect(() => {
        if (state) {
            const index = steps.findIndex(step => step.toLowerCase() === state?.status?.toLowerCase());
            console.log(index);
            setStatus(index)
        }
        getPricing()
    }, [])

    return (
        <div >
            <Dialog slotProps={{
                backdrop: {
                    sx: {
                        backdropFilter: "blur(8px)",
                        backgroundColor: "transparent",
                    }

                },


            }}
                PaperProps={{
                    sx: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        borderRadius:'12px'
                    }
                }} maxWidth={'lg'} sx={{borderRadius:'12px'}} open={open} onClose={handleClose}>

                <DialogContent className={classes.blurredBackground} sx={{ backgroundColor: '#0F172A',overflow:'hidden',display:'flex',alignItems:'center' }}>
                    <Grid container >
                        {allData && <Grid component={'div'} className={`  tshirt-img-scale2`} ref={elementFront} item lg={6} md={12} sx={{ display: 'flex', justifyContent: 'center', height: '570px !important', position: 'relative' }}>
                            <div style={{ position: 'relative' }}>

                                <img width={'500px'} height={'570px'} id="tshirt-backgroundpicture" src={Images[allData?.color ? allData?.color : 'white']} />
                                <div style={{ position: 'absolute', top: 90, left: 145, backgroundColor: 'rgba(0,0,0,0)' }}>
                                    {!rotateDirection && <PDFExport ref={pdfExportComponentFront} paperSize="A4" scale={2.7} fileName="Frontprint">
                                        <div ref={divRef} style={{ backgroundColor: 'transparent' }} id='pdfFront'>
                                            <div className=' pdf-data' ref={parentRef} style={{ width: '220px', height: '313px', position: 'relative', overflow: 'hidden' }}>

                                                {allData?.frontText && allData.frontText.length > 0 && allData.frontText.map((item, index) => {
                                                    console.log(item?.fontSize, 'dedede');
                                                    const textStyle = {
                                                        fontSize: item?.fontSize?.toString().includes('px') ? item.fontSize : `${item.fontSize}px`,
                                                        fontWeight: item.fontWeight,
                                                        fill: item.color,
                                                        fontFamily: item.fontFamily,

                                                    };

                                                    const circlePath = `M ${item.width / 2}, ${item.width / 2} m -${item.width / 3}, 0 a ${item.width / 3},${item.width / 3} 0 1,1 ${2 * (item.width / 3)},0 a ${item.width / 3},${item.width / 3} 0 1,1 -${2 * (item.width / 3)},0`;
                                                    const ellipsePath = `M ${item.width / 2}, ${item.width / 2} m -${item.width / 3}, 0 a ${item.width / 3},${item.width / 6} 0 1,1 ${2 * (item.width / 3)},0 a ${item.width / 3},${item.width / 6} 0 1,1 -${2 * (item.width / 3)},0`;
                                                    const wavePath = `M 10, ${item.width / 2} Q ${item.width / 5}, ${item.width / 5} ${2 * (item.width / 5)}, ${item.width / 2} T ${4 * (item.width / 5)}, ${item.width / 2} T ${item.width - 10}, ${item.width / 2}`;
                                                    const straightPath = `M 0,${item.width / 2} L ${item.width},${item.width / 2}`;

                                                    const shapePaths = {
                                                        circlePath,
                                                        ellipsePath,
                                                        wavePath,
                                                        straightPath,
                                                    };

                                                    const selectedPath = shapePaths[item.shape] || '';
                                                    const uniqueId = `front-circlePath${index}`;

                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div
                                                                id={index}
                                                                style={{
                                                                    position: 'absolute',
                                                                    width: `${item.width}px`,
                                                                    height: `${item.height}px`,
                                                                    top: item?.top?.toString().includes('px') ? item.top : `${item.top}px`,
                                                                    left: item?.left?.toString().includes('px') ? item.left : `${item.left}px`,
                                                                    transform: `rotate(${item.rotation}deg)`,
                                                                    zIndex: item.zIndexes
                                                                }}
                                                            >

                                                                <svg style={{ overflow: 'visible' }} width={item.width + 3} height={item.height} xmlns="http://www.w3.org/2000/svg">

                                                                    <text
                                                                        y={item?.fontSize < 38 ? item?.fontSize - 10 : item?.fontSize}
                                                                        dominantBaseline={item?.fontSize < 38 ? 'middle' : 'auto'}
                                                                        fontSize={item?.fontSize}
                                                                        style={{
                                                                            ...textStyle,
                                                                        }}
                                                                    >

                                                                        {item.text}
                                                                    </text>


                                                                </svg>
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                })}


                                                {allData?.frontPic.length > 0 && allData?.frontPic.map((imageData, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <Grid
                                                                component={'div'}
                                                                className={`Design-${index}`}
                                                                style={{
                                                                    clipPath: imageData.clipPath,
                                                                    width: `${imageData.width}px`,
                                                                    height: `${imageData.height}px`,
                                                                    position: 'absolute',
                                                                    backgroundImage: `url(${imageData?.imageUrl?.includes('amazonaws') ? 'https://corsproxy.io/?' + encodeURIComponent(imageData?.imageUrl) : imageData?.imageUrl})`,
                                                                    backgroundSize: 'contain',
                                                                    backgroundPosition: 'center',
                                                                    backgroundRepeat: 'no-repeat',
                                                                    top: `${imageData.top}px`,
                                                                    left: `${imageData.left}px`,
                                                                    borderRadius: `${imageData.borderRadius}px`,
                                                                    transform: `${imageData.rotation}`,
                                                                    zIndex: imageData?.zIndexes
                                                                }}

                                                            />
                                                            {/* Add overlay image */}
                                                            {checked && <Grid
                                                                component={'div'}
                                                                className={`Overlay-${index}`}
                                                                style={{
                                                                    width: `${imageData.width}px`,
                                                                    height: `${imageData.height}px`,
                                                                    position: 'absolute',
                                                                    top: `${imageData.top}px`,
                                                                    left: `${imageData.left}px`,
                                                                    backgroundImage: `url(${allData?.color == 'white' ? Images?.toneWhite : allData?.color == 'black' ? Images?.toneBlack : allData?.color == 'lightgreen' ? Images?.toneGreen : allData?.color == 'darkgreen' ? Images?.toneDarkGreen : allData?.color == 'blue' ? Images?.toneBlue : allData?.color == 'yellow' ? Images?.toneOrange : allData?.color == 'purple' ? Images?.tonePurple : 'white'})`, // Path to the overlay image
                                                                    backgroundSize: 'contain',
                                                                    backgroundPosition: 'center',
                                                                    backgroundRepeat: 'no-repeat',
                                                                    opacity: 0.5, // Adjust the transparency of the overlay image
                                                                    zIndex: imageData?.zIndexes + 1 // Place overlay above the image
                                                                }}
                                                            />}
                                                        </div>
                                                    );
                                                })}


                                            </div>
                                        </div>
                                    </PDFExport>}

                                </div>
                            </div></Grid>}

                        {allData && <Grid item lg={6} md={12} className={`  tshirt-img-scale2`} ref={elementBack} sx={{ display: 'flex', justifyContent: 'center', height: '570px !important' }}> <div style={{ position: 'relative' }}>
                            <img width={'500px'} height={'570px'} id="tshirt-backgroundpicture" src={Images[allData?.color ? allData?.color + "Back" : 'white' + "Back"]} />
                            <div style={{ position: 'absolute', top: 90, left: 145 }}>

                                <PDFExport ref={pdfExportComponentBack} paperSize="A4" scale={2.7} fileName="Backprint">
                                    <div ref={divRefBack} style={{ backgroundColor: 'transparent' }} id='pdfBack'>
                                        <div className='pdf-data' ref={parentRef} style={{ width: '220px', height: '313px', position: 'relative', overflow: 'hidden' }}>

                                            {allData?.backText && allData?.backText.length > 0 && allData.backText.map((item, index) => {
                                                const textStyle = {

                                                    fontSize: item?.fontSize?.toString().includes('px') ? item.fontSize : `${item.fontSize}px`,
                                                    fontWeight: item.fontWeight,
                                                    fill: item.color,
                                                    fontFamily: item.fontFamily,

                                                };
                                                const circlePath = `M ${item.width / 2}, ${item.width / 2} m -${item.width / 3}, 0 a ${item.width / 3},${item.width / 3} 0 1,1 ${2 * (item.width / 3)},0 a ${item.width / 3},${item.width / 3} 0 1,1 -${2 * (item.width / 3)},0`;
                                                const ellipsePath = `M ${item.width / 2}, ${item.width / 2} m -${item.width / 3}, 0 a ${item.width / 3},${item.width / 6} 0 1,1 ${2 * (item.width / 3)},0 a ${item.width / 3},${item.width / 6} 0 1,1 -${item.width / 3},0`;
                                                const wavePath = `M 10, ${item.width / 2} Q ${item.width / 5}, ${item.width / 5} ${2 * (item.width / 5)}, ${item.width / 2} T ${4 * (item.width / 5)}, ${item.width / 2} T ${item.width - 10}, ${item.width / 2}`;
                                                const straightPath = `M 0,${item.width / 2} L ${item.width},${item.width / 2}`;

                                                const shapePaths = {
                                                    circlePath,
                                                    ellipsePath,
                                                    wavePath,
                                                    straightPath,
                                                };

                                                const selectedPath = shapePaths[item.shape] || '';
                                                const uniqueId = `back-circlePath${index}`;

                                                return (
                                                    <React.Fragment key={index}>
                                                        <ContextMenuTrigger id={`context-menu-back-${index}`}>
                                                            <div
                                                                id={`back${index}`}
                                                                style={{
                                                                    position: 'absolute',
                                                                    width: `${item.width}px`,
                                                                    height: `${item.height}px`,
                                                                    top: item?.top?.toString().includes('px') ? item.top : `${item.top}px`,
                                                                    left: item?.left?.toString().includes('px') ? item.left : `${item.left}px`,
                                                                    transform: `rotate(${item.rotation}deg)`,
                                                                    zIndex: item.zIndexes
                                                                }}>
                                                                <svg style={{ overflow: 'visible' }} width={item.width + 3} height={item.height} xmlns="http://www.w3.org/2000/svg">

                                                                    <text
                                                                        y={item?.fontSize < 38 ? item?.fontSize - 10 : item?.fontSize}
                                                                        dominantBaseline={item?.fontSize < 38 ? 'middle' : 'auto'}
                                                                        fontSize={item?.fontSize}
                                                                        style={{
                                                                            ...textStyle,
                                                                        }}
                                                                    >

                                                                        {item.text}
                                                                    </text>


                                                                </svg>
                                                            </div>
                                                        </ContextMenuTrigger>
                                                    </React.Fragment>
                                                );
                                            })}

                                            {allData?.backPic.length > 0 && allData?.backPic.map((imageUrl, index) => (
                                                <div key={index}>
                                                    <Grid
                                                        component={'div'}
                                                        className={`Design2-${index}`}
                                                        style={{
                                                            clipPath: allData?.backPic[index].clipPath,
                                                            width: `${allData?.backPic[index].width}px`,
                                                            height: `${allData?.backPic[index].height}px`,
                                                            position: 'absolute',
                                                            backgroundImage: `url(${imageUrl?.imageUrl?.includes('amazonaws') ? 'https://corsproxy.io/?' + encodeURIComponent(imageUrl?.imageUrl) : imageUrl?.imageUrl})`, // Use the base64 image
                                                            backgroundSize: 'contain',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                            top: `${allData?.backPic[index].top}px`,
                                                            left: `${allData?.backPic[index].left}px`,
                                                            borderRadius: ` ${allData?.backPic[index].borderRadius}px`,
                                                            transform: `${allData?.backPic[index].rotation}`,
                                                            zIndex: imageUrl?.zIndexes
                                                        }}
                                                    />
                                                    {false && <Grid
                                                        component={'div'}
                                                        className={`Overlay2-${index}`}
                                                        style={{
                                                            width: `${imageUrl.width}px`,
                                                            height: `${imageUrl.height}px`,
                                                            position: 'absolute',
                                                            top: `${imageUrl.top}px`,
                                                            left: `${imageUrl.left}px`,
                                                            backgroundImage: `url(${allData?.color == 'white' ? Images?.toneWhite : allData?.color == 'black' ? Images?.toneBlack : allData?.color == 'lightgreen' ? Images?.toneGreen : allData?.color == 'darkgreen' ? Images?.toneDarkGreen : allData?.color == 'blue' ? Images?.toneBlue : allData?.color == 'yellow' ? Images?.toneOrange : allData?.color == 'purple' ? Images?.tonePurple : 'white'})`, // Path to the overlay image
                                                            backgroundSize: 'contain',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                            opacity: 0.5, // Adjust the transparency of the overlay image
                                                            zIndex: imageUrl?.zIndexes + 1 // Place overlay above the image
                                                        }}
                                                    />}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </PDFExport>
                            </div>
                        </div></Grid>}
                    </Grid>
                </DialogContent>

            </Dialog>
            <Box
                sx={{
                    backgroundImage: `url("${Images.contactBg}")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: '300px', // Adjust height as needed
                    display: 'flex',
                    alignItems: 'center', justifyContent: 'center',
                    position: "relative"
                }}
            >
                {/* <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: Colors.backgroundColor1
                    }}
                /> */}
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <p className='banner-text heading-font' style={{ fontSize: '35px', textAlign: 'center', fontFamily: "Noto Sans, sans-serif", }}> Order Detail  </p>
                    {/* <p style={{ fontSize: '25px', textAlign: 'center', color: 'white', fontFamily: "Noto Sans, sans-serif", }}>Homepage || <span style={{ color: Colors.secondary }}>Contact Us</span></p> */}
                </Box>
            </Box>
            <Box sx={{ width: '100%', margin: '0 auto', p: 12, borderRadius: '12px' }} >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '85%' }}>
                    <Box>
                        <Box sx={{ color: '#7F92B0', fontSize: '16px' }}>Order ID</Box>
                        <Box component={'div'} className='heading-font' sx={{ fontWeight: 700, fontSize: '24px' }}>{state?.order_no}</Box>
                    </Box>
                    <Box>
                        <Button
                            variant="outlined"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                padding: "5px 65px",
                                borderRadius: "8px",
                                textTransform: "none",
                                fontSize: "16px",
                                fontWeight: "bold",
                                backgroundColor: state?.status == 'delivered' ? "#143D39" : '#5A4A1E', // Dark green background
                                border: state?.status == 'delivered' ? "2px solid #3FBF43" : "2px solid #D4AF37", // Green border
                                color: "#FFFFFF", // White text

                            }}
                        >
                            <CircleIcon sx={{ fontSize: 12, color: "white" }} />
                            <Typography sx={{ textTransform: 'capitalize' }}>{state?.status}</Typography>
                        </Button>
                    </Box>
                </Box>
                <Grid container gap={2}>
                    <Grid item lg={5} p={3} mt={4} sx={{ cursor: 'pointer', backgroundColor: '#1b2335' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '96%' }}>

                            <Box component={'div'} className='heading-font2' sx={{ fontWeight: 400, color: 'white !important' }}>Order Details</Box>

                        </Box>
                        {console.log(state)}
                        <Divider style={{ width: '100%', borderColor: '#B0B0B04D', marginTop: '20px' }} />
                        <Grid container sx={{ mb: 2, mt: 2 }} alignItems={'center'} >

                            <Grid item xs={3} sx={{ color: 'white', fontWeight: 600, fontSize: '14px' }} mt={2}>Total Designs</Grid>
                            <Grid item xs={3} sx={{ color: 'white', fontWeight: 600, fontSize: '14px' }} mt={2}>{state?.designs?.length}</Grid>

                            <Grid item xs={3} sx={{ color: 'white', fontWeight: 600, fontSize: '14px' }} mt={2}>Total Quantity</Grid>
                            <Grid item xs={3} sx={{ color: 'white', fontWeight: 600, fontSize: '14px' }} mt={2}>{state?.total_qty}</Grid>
                            <Grid item xs={3} sx={{ color: 'white', fontWeight: 600, fontSize: '14px' }} mt={2}>Total Pricing</Grid>
                            <Grid item xs={3} sx={{ color: 'white', fontWeight: 600, fontSize: '14px' }} mt={2}>{state?.total_amount}</Grid>
                            <Grid item xs={3} sx={{ color: 'white', fontWeight: 600, fontSize: '14px' }} mt={2}>Payment Method</Grid>
                            <Grid item xs={3} sx={{ color: 'white', fontWeight: 600, fontSize: '14px' }} mt={2}>Card</Grid>
                            <Grid item xs={3} sx={{ color: 'white', fontWeight: 600, fontSize: '14px' }} mt={2}>Promo Code</Grid>
                            <Grid item xs={3} sx={{ color: 'white', fontWeight: 600, fontSize: '14px' }} mt={2}>{state?.promo_code}</Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={5} p={3} mt={4} sx={{ cursor: 'pointer', backgroundColor: '#1b2335' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '96%' }}>

                            <Box component={'div'} className='heading-font2' sx={{ fontWeight: 400, color: 'white !important' }}>Estimated Arrival</Box>

                        </Box>

                        <Divider style={{ width: '100%', borderColor: '#B0B0B04D', marginTop: '20px' }} />
                        <Grid container sx={{ mb: 2, mt: 2 }} alignItems={'center'} >

                            <Grid item xs={6} sx={{ color: '#0BD1D1', fontWeight: 600 }} mt={2}>TBD</Grid>


                        </Grid>

                    </Grid>
                    <Grid container mt={4}>
                        <Box component={'div'} className='heading-font' sx={{ fontWeight: 700, fontSize: '24px' }}>Items</Box>
                    </Grid>
                    <Grid container gap={2}>
                        {state?.details?.length > 0 ? (
                            state?.details.map((item, index) => (

                                <Grid
                                    item
                                    lg={3}
                                    md={3}
                                    sm={12}
                                    xs={12}
                                    display="flex"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    gap={2}
                                    p={2}
                                    sx={{ backgroundColor: '#1b2335' }}
                                >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            width: '100px',
                                            height: 'auto',
                                            '&:hover .overlay': {
                                                opacity: 1,
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{

                                                backgroundColor: '#0BD1D14D',
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Box
                                                component="img"
                                                src={item.generated_art} // Use item property correctly
                                                alt="Item"
                                                sx={{
                                                    width: '80px',
                                                    height: 'auto',
                                                    borderRadius: 1,
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            className="overlay"
                                            component={'div'}
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',  // semi-transparent overlay
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                opacity: 0,
                                                transition: 'opacity 0.3s ease',
                                                borderRadius: 1,
                                                color: 'white',
                                                 cursor:'pointer'
                                            }}

                                            onClick={() => { setOpen(true); setAllData(state?.designs[item?.design_index]) }}
                                        >
                                            <Button
                                               
                                                sx={{ color: 'white', textTransform: 'none' }}
                                            >
                                                Preview
                                            </Button>
                                        </Box>
                                    </Box>

                                    {/* Item Details */}
                                    <Box>
                                        <Typography variant="body2" sx={{ color: 'rgb(141, 145, 154)', mt: 1 }}>
                                            Age Group: <span style={{ color: 'white' }}>{item.size_type || 'N/A'}</span>
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'rgb(141, 145, 154)', mt: 1 }}>
                                            Size: <span style={{ color: 'white' }}>{item.size || 'N/A'}</span>
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'rgb(141, 145, 154)', mt: 1 }}>
                                            Shirt Quality: <span style={{ color: 'white', textTransform: 'capitalize' }}>{item.shirt_type || 'N/A'}</span>
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'rgb(141, 145, 154)', mt: 1 }}>
                                            Print Quality: <span style={{ color: 'white', textTransform: 'capitalize' }}>{item.print_intensity || 'N/A'}</span>
                                        </Typography>
                                    </Box>
                                </Grid>

                            ))
                        ) : (
                            <Typography sx={{ color: 'white', textAlign: 'center' }}>
                                Your cart is empty.
                            </Typography>
                        )}

                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item sm={12} md={10} sx={{ borderRadius: '12px', mt: 10 }}>
                        <Box >
                            <Typography className='heading-font' sx={{ color: Colors.secondary }} variant="h6" gutterBottom>
                                Order Summary
                            </Typography>
                            <div style={{ color: '#fff' }}>

                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px', fontFamily: 'Poppins, sans-serif' }}>
                                    <span>Items ({state?.total_qty})</span>
                                    <span>AED {state?.sub_amount}</span>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', fontFamily: 'Poppins, sans-serif' }}>
                                    <span>Shipping :</span>
                                    <span>AED {state?.delivery_charges}</span>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', fontFamily: 'Poppins, sans-serif' }}>
                                    <span>Discount :</span>
                                    <span>AED {state?.promo_discount}</span>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', fontFamily: 'Poppins, sans-serif' }}>
                                    <span>Sub Total :</span>
                                    <span>AED {parseFloat(parseFloat(state?.sub_amount) + parseFloat(state?.delivery_charges)) - parseFloat(state?.promo_discount)}</span>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', fontFamily: 'Poppins, sans-serif' }}>
                                    <span>VAT 5% :</span>
                                    <span>
                                        AED {state?.vat}
                                    </span>
                                </div>
                                <hr style={{ borderColor: '#fff', margin: '20px 0' }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px', fontWeight: 'bold', fontFamily: 'Poppins, sans-serif', color: Colors.secondary }}>
                                    <span>Order Total:</span>
                                    <span>AED {state?.total_amount}</span>
                                </div>
                                <hr style={{ borderColor: '#fff', margin: '20px 0' }} />

                            </div>
                        </Box>

                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default OrderDetail
