import React, { useContext, useState } from 'react';
import { Box, Button, CardMedia, Container, Grid, IconButton, ImageList, ImageListItem, Modal, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';


import { East } from '@mui/icons-material';
import { Images } from '../../assests';
import Colors from '../../assests/style';
import moment from 'moment';
import { getAuth, signInWithPopup } from 'firebase/auth';
import AuthServices from '../../services/AuthServices';
import { provider } from '../../config/firebase.config';
import { AuthContext } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { SecondaryButton } from '../../components/Buttons';
import Swal from 'sweetalert2';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif'// You can change this to your preferred font
  },
  components: {
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: 'rgba(255, 255, 255, 0.7)',
          fontFamily: 'Poppins, sans-serif',
          '&.Mui-active': {
            color: '#ffffff',
          },
          '&.Mui-completed': {
            color: '#ffffff',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            color: 'white', // Text color,
            fontFamily: 'Poppins, sans-serif'
          },
          '& .MuiInputLabel-root': {
            color: 'white', // Label color
          },
          '& .MuiInput-underline:before': {
            borderBottomColor: 'white', // Underline color when unfocused
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'white', // Underline color when focused
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white', // Border color when unfocused
            },
            '&:hover fieldset': {
              borderColor: 'white', // Border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white', // Border color when focused
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: 'white', // Text color for input
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'white', // Border color for input
        },
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'white', // Text color for label
          borderColor: 'white',
          '&.Mui-focused': {
            color: 'white',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: 'black', // Text color for menu items
          backgroundColor: 'white', // Background color for menu items
          '&.Mui-selected': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)', // Background color for selected item
          },
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)', // Background color for hover
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: 'white', // Color for dropdown arrow
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#1976d2',
    },
    background: {
      default: '#333333',
    },
  },

});

function Footer() {
  const auth = getAuth();
  const { register, handleSubmit, formState: { errors } } = useForm();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = data => {
    // Handle form submission
    console.log(data);
    handleClose(); // Close the modal after submission
  };
  let User = localStorage.getItem('user')
  User = JSON.parse(User)
  const navigate = useNavigate()
  const { user, setUser } = useContext(AuthContext);
  console.log(user, 'contextdata');
  const [userData, setUserData] = useState()
  const [open, setOpen] = useState(false)

  let itemData = [{ img: Images.collection1 }, { img: Images.collection2 }, { img: Images.collection3 }, { img: Images.collection4 }]
  const handleGoogleLogin = async () => {

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("User Info: ", user);
      

      authLogin(user?.reloadUserInfo,result)
      // Handle user info here (e.g., save to state, context, or redirect)
    } catch (error) {
      console.error("Error during Google login: ", error);
    }
  };

  const authLogin = async (sendData,result) => {
    console.log(sendData, 'data');
    try {
      let obj = {
        phone: '',
        google_id: sendData.localId,
        email: sendData.email,
        picture: sendData.photoUrl,
        name: sendData.displayName
      }
      const data  = await AuthServices.authLogin(obj)
      console.log(data);
      if (data.responseCode == 200) {

        localStorage.setItem('authToken', JSON.stringify(data?.data?.token))
        localStorage.setItem('user', JSON.stringify(sendData))
        let userInfo = localStorage.getItem('user')
        setUserData(JSON.parse(userInfo))
        setUser(result.user)
      }
      


    } catch (error) {
      JSON.Stringyfy(error)
    } finally {

    }
  }
  return (
    <Box
      component={"footer"}
      sx={{
        backgroundColor: Colors.backgroundColor2,
        pt: "60px",
        pb: "20px",
      }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ThemeProvider theme={theme}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '80%', sm: '70%', md: '50%' },


            bgcolor: '#0c1427',
            boxShadow: 24,
            p: 4,
            borderRadius: 2
          }}>
            <Box component={'div'} onClick={() => setOpen(false)} sx={{ display: 'flex', justifyContent: 'flex-end', color: "white", fontFamily: 'Poppins, sans-serif', cursor: 'pointer' }}>X</Box>
            <Typography id="modal-title" variant="h6" sx={{ color: 'white', textAlign: 'center' }} component="h2">
              Become A Partner
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} mt={5} mb={5}>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Your Name"
                    {...register('name', { required: true })}
                    error={!!errors.name}
                    sx={{ input: { color: 'white' }, label: { color: 'white' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
                    helperText={errors.name ? 'Name is required' : ''}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Email Address"
                    {...register('email', {
                      required: true,
                      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                    })}
                    error={!!errors.email}
                    sx={{
                      input: { color: 'white' },
                      label: { color: 'white' },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                        '&:hover': {
                          borderColor: 'white'
                        }
                      }
                    }}
                    helperText={errors.email ? 'Invalid email address' : ''}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Phone Number"
                    {...register('phone')}
                    type='number'

                    sx={{ input: { color: 'white' }, label: { color: 'white' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}

                    fullWidth
                  />
                </Grid>
                {/* <Grid item xs={6} md={6}>
                  <TextField
                    label="Subject"
                    {...register('subject', { required: true })}
                    error={!!errors.subject}
                    sx={{ input: { color: 'white' }, label: { color: 'white' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
                    helperText={errors.subject ? 'Subject is required' : ''}
                    fullWidth
                  />
                </Grid> */}
                {/* <Grid item xs={12}>
                  <TextField
                    label="Message"
                    {...register('message', { required: true })}
                    error={!!errors.message}
                    sx={{ input: { color: 'white' }, label: { color: 'white' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
                    helperText={errors.message ? 'Message is required' : ''}
                    multiline
                    rows={6}
                    fullWidth
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <SecondaryButton
                    type={'submit'}
                    bgColor={'#0bc1d9'} fullWidth={true} className={'checkout-btn'} iconPosition={'end'} icon={<ArrowForwardIcon sx={{ color: '#0C1427' }} />} p={"15px 40px"} fontWeight={'bold'} color={'#0C1427'} title={'Become A Partner'} />
                </Grid>
              </Grid>
            </form>
          </Box>
        </ThemeProvider>
      </Modal>
      <Container >
        <Grid
          container
         
        >
          <Grid item md={12} sm={12} xs={12}>
            <Grid container >
              <Grid item md={3} sm={6} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    alignItems: { xs: "center", sm: "flex-start", md: "flex-start" }
                  }}
                >
                  <CardMedia component={"img"} src={Images.mainlogo} sx={{ width: "60%", objectFit: "contain" }} />
                  
                  <Typography sx={{ color: Colors.white,fontSize:'14px' }}>
                  &nbsp; +1 (7635) 547-12-97


                  </Typography>
                  <Typography sx={{ color: Colors.white,fontSize:'14px' }}>
                  &nbsp; hello@printmeup.ai


                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
              <Typography className='heading-font' sx={{ color: Colors.secondary, fontWeight: 600,textAlign:'center' }}>Useful Links</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    justifyContent: 'center',
                    mt:2
                  }}
                >

                  {<Button sx={{ color: Colors.textColor1, textTransform: "capitalize" }} onClick={() => {
                    let User = localStorage.getItem('user')
                    User = JSON.parse(User)
                    if (User) {
                      window.location.href = '/my-account'

                    }
                    else {
                      handleGoogleLogin()
                    }
                  }}>My Account</Button>}

                  <Button onClick={() => navigate('/privacypolicy')} sx={{ color: Colors.textColor1, textTransform: "capitalize" }}>Privacy Policy</Button>
                  <Button onClick={() => navigate('/termsandconditions')} sx={{ color: Colors.textColor1, textTransform: "capitalize" }}>Terms & Conditions</Button>
                </Box>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
              <Typography className='heading-font' sx={{ color: Colors.secondary, fontWeight: 600 }}>Support</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    alignItems: { xs: "center", sm: "flex-start", md: "flex-start" }
                  }}
                >
                

                  <Button  onClick={() => window.location.href = 'contact-us'} sx={{ color: Colors.textColor1, textTransform: "capitalize" ,mt:2,p:0}}>Contact Us</Button>
                  <Button onClick={() => window.open('https://tawk.to/chat/67358a912480f5b4f59dd31a/1ickiaioe', '_blank')} sx={{ color: Colors.textColor1, textTransform: "capitalize",p:0 }}>Chat With Us</Button>
                </Box>
              </Grid>
           
              
              {/* <Grid item md={4} sm={6} xs={12}>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    alignItems: { xs: "center", sm: "flex-start", md: "flex-start" }
                  }}
                >
                  <Typography sx={{ color: Colors.secondary, fontWeight: 600 }}>Collection</Typography>
                  <ImageList sx={{ width: 200, height: 200 }} cols={2} >
                    {itemData.map((item) => (
                      <ImageListItem key={item.img}>
                        <img
                          srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                          alt={item.title}
                          loading="lazy"
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>

                </Box>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item md={12} sm={12} sx={12}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}>
                {/* <CardMedia component={"img"} src={Images.footerPayment} sx={{ width: "50%", objectFit: "contain" }} /> */}
                <Typography sx={{ color: Colors.textColor2 }}>© {moment().format('YYYY')} Print Me Up. All rights reserved.</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Footer