import { Box, Grid } from '@mui/material'
import React from 'react'
import Colors from '../../../assests/style'
import { Images } from '../../../assests'

const ComingSoon = () => {
    return (
        <div >
            <Box
                sx={{
                    backgroundImage: `url(${Images.HeroSectionImage})`,
                    backgroundSize: 'cover', // Adjust the image size
                    backgroundPosition: 'center', // Position the image
                    backgroundRepeat: 'no-repeat', // Avoid repeating the image
                    height: { lg: '100vh', md: "100vh" }, // Adjust the height as needed
                    position: 'relative',
                    overflow: 'hidden',
                    display:'flex',
                    alignItems:'center'
                }}
            >

                {/* Header */}

                <Grid id="home-section" container sx={{ gap: 2, justifyContent: 'center',flexDirection: 'column' ,mt:1}}>
                    <Box display={'flex'} justifyContent={'center'}>
                        <Box component={'img'} src={Images.mainlogo} width={'300px'} sx={{ textAlign: 'center' }}>

                        </Box>
                    </Box>
                    <Grid item lg={12} md={12} xs={12}>
                        <Box sx={{ textAlign: 'center' }} >
                            <p className='heading-font2' style={{ color: Colors.white, fontSize: '30px', fontWeight: '600' }}>
                                Being <span className='banner-text' style={{ fontSize: '50px', fontWeight: 'bold' }} >Unique</span> 
                                <span style={{ fontSize: '40px' }}> is better </span> <br/>
                                <span style={{ fontSize: '40px' }}>  than being </span>
                                <span className='banner-text' style={{ fontSize: '50px', fontWeight: 'bold' }} >Perfect</span> <br />



                            </p>
                        </Box>



                    </Grid>
                    <Box display={'flex'} justifyContent={'center'}>
                        <Box component={'img'} src={Images.catImage} width={'300px'} sx={{ textAlign: 'center' }}>

                        </Box>
                    </Box>
                    <Box sx={{ textAlign: 'center' }} >
                        <p className='heading-font2' style={{ fontSize: '40px', fontWeight: 600, color: 'white' }}> Coming Soon </p>



                    </Box>


                </Grid>
            </Box>

        </div>
    )
}

export default ComingSoon
