import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Container,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Button,
    MenuItem,
    CardMedia,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    useScrollTrigger,
    Slide,
    Badge,
    styled,
    ClickAwayListener,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import Colors from '../../assests/style';
import { Images } from '../../assests';
import { Link, useNavigate } from 'react-router-dom';
import { auth, provider, signInWithPopup } from '../../config/firebase.config'; // Adjust the import path according to your file structure
import { getAuth, GoogleAuthProvider, signOut } from 'firebase/auth';
import AuthServices from '../../services/AuthServices';
import { AuthContext } from '../../Context/AuthContext';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { CartContext } from '../../Context/CartContext';
import Swal from 'sweetalert2';
import SignInModal from '../../components/LoginModal';

function HideOnScroll(props) {

    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}
const SmallBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        minWidth: '10px',
        height: '10px',
        fontSize: '10px',
        padding: '0',
    },
}));
function Header() {

    const { cartVisible, toggleCartVisibility } = useContext(CartContext);
    let User = localStorage.getItem('user')
    User = JSON.parse(User)
    const [userData, setUserData] = useState()
    const auth = getAuth();
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false)
    const [currentState, setCurrentState] = useState('login')
    const [dropdown, setDropdown] = useState(false)
    const { user, setUser } = useContext(AuthContext);
    const navigate = useNavigate();
    console.log(user, 'useruseruser');
    const [cartData, setCartData] = useState(null)
    const handleOpenDrawer = (event) => {
        setOpen(event.currentTarget);
    };

    const scrollToId = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleCloseDrawer = () => {
        setOpen(false);
    };
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

        setDropdown(true)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setDropdown(false);
    };

    const handleProfile = () => {
        // Handle profile click
        console.log('Profile clicked');
        setOpen(false)
        window.location.href = '/my-account'
        handleClose();
    };



    const pages = [{ name: 'Home', url: '/' }, { name: 'Collection', url: '/collections' }, { name: 'How it Works', url: '', id: 'how-section' }, { name: 'Pricing', url: '', id: 'pricing-section' }, { name: 'Contact Us', url: '/contact-us' }];

    const handleGoogleLogin = async () => {

        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            console.log("User Info: ", user);


            authLogin(user?.reloadUserInfo, result)
            // Handle user info here (e.g., save to state, context, or redirect)
        } catch (error) {
            console.error("Error during Google login: ", error);
        }
    };

    const authLogin = async (sendData, result) => {
        console.log(sendData, 'data');
        try {
            let obj = {
                phone: '',
                google_id: sendData.localId,
                email: sendData.email,
                picture: sendData.photoUrl,
                name: sendData.displayName
            }
            const data = await AuthServices.authLogin(obj)
            console.log(data);
            if (data?.responseCode == 200) {
                console.log(data);
                localStorage.setItem('authToken', JSON.stringify(data?.data?.token))
                localStorage.setItem('user', JSON.stringify(sendData))
                let userInfo = localStorage.getItem('user')
                setUserData(JSON.parse(userInfo))
                setUser(result.user)

            }



        } catch (error) {
            console.log('asdaS');
            JSON.Stringyfy(error)

        } finally {

        }
    }
    const handleClose2 = () => {

        setOpen2(false);

    };

    const generateSession = async (sendData) => {
        console.log(sendData, 'data');
        try {

            const { data } = await AuthServices.generateSession()
            console.log(data);



        } catch (error) {
            console.log(error);
        } finally {

        }
    }
    const handleLogout = async () => {
        try {
            localStorage.clear()
            setOpen(false)
            navigate('/')
            let user = localStorage.getItem('user')
            setUserData(JSON.parse(user))
            await signOut(auth);
            console.log("User signed out");
        } catch (error) {
            console.error("Error during sign out: ", error);
        }
    };
    useEffect(() => {
        let user = localStorage.getItem('user')
        setUserData(JSON.parse(user))
        setUser('')
        generateSession()
        let cartdata = localStorage.getItem('orderData')
        console.log(cartdata);
        if (cartdata) {
            console.log(cartdata, 'cartdatacartdata');
            setCartData(cartdata)
            // toggleCartVisibility()
        }
        else {
            setCartData(null)

        }
    }, [])
    const handleCloseDropdown = () => {

        setAnchorEl(null);

    };
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);


    return (
        <>
            <SignInModal
                open={open2}
                handleClose={handleClose2}
                screen={currentState}
                handleGoogleLogin={handleGoogleLogin}

            />
            <HideOnScroll >

                <Box sx={{  backgroundColor: scrolled ? "#0D1426 " : "transparent", zIndex: '999 !important', position: 'fixed', width: '100%' }}>
                    <Container sx={{ maxWidth: "1920px !important", px: { md: "70px" } }}>
                        <Toolbar sx={{ flexDirection: { lg: "row", md: "row", sm: 'row-reverse', xs: 'row-reverse' }, justifyContent: 'space-between', gap: 5 }} disableGutters>
                            <CardMedia
                                component={"img"}
                                src={Images.mainlogo}
                                sx={{
                                    width: "160px",
                                    objectFit: "contain",
                                    display: { xs: 'none', md: 'block' }
                                }}
                                onClick={() => navigate("/")}
                            />

                            <Box sx={{ display: { xs: 'flex', md: 'none' }, flexDirection: 'row-reverse', justifyContent: 'space-between', alignItems: 'center', mt: '15px' }}>

                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenDrawer}
                                    sx={{
                                        color: Colors.primary
                                    }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                {!User ? <> <Box sx={{ display: { lg: 'flex', md: 'flex', sm: "none", xs: 'none' } }}><PrimaryButton
                                    title={"Login"}

                                    onClick={() => { setOpen2(true); setCurrentState('login') }}
                                />
                                    <PrimaryButton
                                        title={"SignUp"}
                                        color={'#0F172A'}
                                        backgorundColor={'#0EA5EA'}
                                        onClick={() => { setOpen2(true); setCurrentState('register') }}
                                    />
                                </Box>
                                </>

                                    : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        {( cartData) ? <Box component={'div'} onClick={() => navigate('/cart')}> <SmallBadge badgeContent={''} color="primary"> <ShoppingCartIcon  sx={{ cursor: "pointer", color: 'white' }} /></SmallBadge></Box> : <ShoppingCartIcon sx={{ cursor: "pointer", color: 'white' }} />} &nbsp;&nbsp;
                                        <IconButton
                                            edge="end"
                                            color="inherit"
                                            aria-label="account of current user"
                                            aria-controls="profile-menu"
                                            // aria-haspopup="true"
                                            onClick={(event) => handleClick(event)}
                                        >
                                            <AccountCircleIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                        {dropdown && (
                                            <Menu
                                                id="profile-menu"
                                                anchorEl={anchorEl}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={handleProfile}>Profile</MenuItem>
                                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                            </Menu>
                                        )}


                                    </div>}
                                <Drawer
                                    anchor='right'
                                    open={open}
                                    onClose={handleCloseDrawer}
                                    PaperProps={{
                                        sx: {
                                            width: "60%",
                                            background: Colors.backgroundColor1
                                        }
                                    }}
                                >
                                    <List>
                                        {pages.map((item, ind) => (
                                            <ListItem key={ind} button onClick={() => {
                                                setOpen(false)
                                                if (item?.url) {
                                                    navigate(item.url)
                                                }
                                                else {
                                                    const pathname = window.location.pathname;
                                                    console.log(pathname);
                                                    if (pathname != '/') {
                                                        navigate('/')

                                                        setTimeout(() => {
                                                            scrollToId(item?.id);
                                                        }, 1000);
                                                    }
                                                    scrollToId(item?.id)
                                                }
                                            }}>

                                                <ListItemText sx={{ color: Colors.white }} primary={item.name} />
                                            </ListItem>
                                        ))}

                                        {!User ? <ListItem> <Box sx={{ display: "flex", gap: 2 }}><PrimaryButton
                                            title={"Login"}

                                            onClick={() => { setOpen2(true); setCurrentState('login') }}
                                        />
                                            <PrimaryButton
                                                title={"Register"}
                                                color={'#fff'}
                                                backgorundColor={'#0EA5EA'}
                                                onClick={() => { setOpen2(true); setCurrentState('register') }}
                                            />
                                        </Box>
                                        </ListItem>
                                            : <div style={{ marginTop: '-5px' }}>
                                                <List>
                                                    <ListItem>
                                                        <ListItemText button onClick={handleProfile} sx={{ color: Colors.white, cursor: 'pointer', mb: '0px', mt: '0px' }} primary={'My Account'} />
                                                    </ListItem>
                                                </List>
                                                <List>
                                                    <ListItem>
                                                        <ListItemText button onClick={handleLogout} sx={{ color: Colors.white, cursor: 'pointer', mt: '0px', mb: '0px' }} primary={'Logout'} />
                                                    </ListItem></List>
                                            </div>}


                                    </List>
                                </Drawer>
                            </Box>
                            <Link to="/">
                                <CardMedia
                                    component={"img"}
                                    src={Images.mainlogo}
                                    sx={{
                                        width: "160px",
                                        objectFit: "contain",
                                        display: { xs: 'flex', md: 'none' }, mr: 1
                                    }}
                                />
                            </Link>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: { xs: 'none', md: 'flex' },
                                    justifyContent: "flex-start",
                                    gap: 5
                                }}
                            >
                                {pages.map((page) => (
                                    <Box button onClick={() => {
                                        if (page?.url) {
                                            navigate(page.url)
                                        }
                                        else {
                                            const pathname = window.location.pathname;
                                            console.log(pathname);
                                            if (pathname != '/') {
                                                navigate('/')

                                                setTimeout(() => {
                                                    scrollToId(page?.id);
                                                }, 1000);
                                            }
                                            scrollToId(page?.id)

                                        }
                                    }}>
                                        <Button
                                            key={page}
                                            onClick={handleCloseDrawer}
                                            sx={{ my: 2, color: 'white', display: 'block', color: Colors.white, textTransform: "capitalize" }}
                                        >
                                            {page.name}
                                        </Button>
                                    </Box>
                                ))}
                            </Box>
                            <Box
                                sx={{
                                    display: { xs: 'none', md: 'flex' },
                                    gap: "16px"
                                }}
                            >
                                {!User ? <>

                                    <PrimaryButton
                                        title={"Signup"}
                                        color={'#0F172A'}
                                        backgorundColor={'#0EA5EA'}
                                        sx={{
                                            ":hover": {
                                                color: '#0F172A',
                                                backgroundColor: '#0EA5EA'
                                            },
                                        }}
                                        borderRadius={'0px'}
                                        onClick={() => { setOpen2(true); setCurrentState('register') }}
                                    />
                                    <PrimaryButton
                                        title={"Login"}
                                        sx={{
                                            border: "5px solid #0EA5EA", ":hover": {
                                                borderWidth: "5px"
                                            },
                                        }}
                                        color={'#0EA5EA'}
                                        borderRadius={'0px'}
                                        onClick={() => { setOpen2(true); setCurrentState('login') }}
                                    />

                                </>

                                    : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        {true ? <Box component={'div'} onClick={() => navigate('/cart')}> <SmallBadge badgeContent={''} color="primary"> <ShoppingCartIcon sx={{ cursor: "pointer", color: 'white' }} /></SmallBadge></Box> : <ShoppingCartIcon sx={{ cursor: "pointer", color: 'white' }} />} &nbsp;&nbsp;
                                        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                                            <div>
                                                <IconButton
                                                    edge="end"
                                                    color="inherit"
                                                    aria-label="account of current user"
                                                    aria-controls="profile-menu"
                                                    onClick={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                                                >
                                                    <AccountCircleIcon sx={{ color: 'white' }} />
                                                </IconButton>
                                                <Menu
                                                    id="profile-menu"
                                                    anchorEl={anchorEl}
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleCloseDropdown}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    keepMounted
                                                    sx={{
                                                        '& .MuiPaper-root': {
                                                            backgroundColor: '#101A33', // Background color for the dropdown
                                                            borderRadius: '0px',        // Rounded corners
                                                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Shadow effect
                                                            padding: '8px 0',           // Padding for the menu
                                                            minWidth: '150px',          // Minimum width
                                                            color: '#858a97',

                                                        },
                                                    }}
                                                >
                                                    <MenuItem
                                                        onClick={handleProfile}
                                                        sx={{
                                                            '&:hover': {
                                                                textDecoration: 'underline', // Underline on hover
                                                            },
                                                            fontSize: '15px'
                                                        }}
                                                    >
                                                        My Account
                                                    </MenuItem>
                                                    <MenuItem
                                                        sx={{
                                                            '&:hover': {
                                                                textDecoration: 'underline',
                                                            },
                                                            fontSize: '15px'
                                                        }}
                                                    >
                                                        My Address
                                                    </MenuItem>
                                                    <MenuItem
                                                        sx={{
                                                            '&:hover': {
                                                                textDecoration: 'underline',
                                                            },
                                                            fontSize: '15px'
                                                        }}
                                                    >
                                                        My Orders
                                                    </MenuItem>
                                                    <MenuItem
                                                        sx={{
                                                            '&:hover': {
                                                                textDecoration: 'underline',
                                                            },
                                                            fontSize: '15px'
                                                        }}
                                                    >
                                                        My Library
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={handleLogout}
                                                        sx={{
                                                            '&:hover': {
                                                                textDecoration: 'underline',
                                                            },
                                                            fontSize: '15px'
                                                        }}
                                                    >
                                                        Logout
                                                    </MenuItem>
                                                </Menu>
                                            </div>
                                        </ClickAwayListener>
                                    </div>}
                            </Box>
                        </Toolbar>
                    </Container>
                </Box>
            </HideOnScroll>
        </>
    )
}

export default Header