import React, { useContext, useEffect, useState } from 'react';
import { Dialog, Grid, Box, Typography, TextField, Button, InputAdornment, IconButton, Stack, colors } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Images } from '../../assests';
import { useForm } from 'react-hook-form';
import AuthServices from '../../services/AuthServices';
import { AuthContext } from '../../Context/AuthContext';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import OTPInput from 'react-otp-input';


const SignInModal = ({ open, handleClose, handleGoogleLogin, screen }) => {
    console.log(screen);

    const [currentState, setCurrentState] = useState('login')
    const { user, setUser } = useContext(AuthContext);
    const [showPasswordLogin, setShowPasswordLogin] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)
    const [error, setError] = useState(false)
    const [otpEnable, setOtpEnable] = useState(false)
    const [otp, setOtp] = useState('');
    const [userObj, setUserObj] = useState(null)
    const [otpToken, setOtpToken] = useState(null)
    const {
        register,
        handleSubmit,
        getValues,
        setValue,

        formState: { errors },
        reset
    } = useForm();
    const {
        register: register2,
        handleSubmit: handleSubmit2,
        setValue: setValue2,
        getValues: getValues2,
        formState: { errors: errors2 },

    } = useForm();
    useEffect(() => {
        if (screen) {
            setCurrentState(screen)
        }

    }, [])


    const handleLogin = async (sendData, result) => {
        console.log(sendData, 'data');
        try {
            let obj = {

                email: sendData.login,
                password: sendData.password
            }
            const data = await AuthServices.authLoginViaEmail(obj)
            console.log(data);
            if (data?.responseCode == 200) {

                localStorage.setItem('authToken', JSON.stringify(data?.data?.token))
                localStorage.setItem('user', JSON.stringify(data?.data))
                let userInfo = localStorage.getItem('user')
                setError('')
                setUser(data?.data)
                handleClose()


            }



        } catch (error) {
            setError(error)
            console.log(error)

        } finally {

        }
    }
    const handleRegister = async (sendData, result) => {
        console.log(sendData, 'data');
        try {
            let obj = {

                name: sendData.name,
                email: sendData.email,
                password: sendData.password
            }
            setUserObj(obj)
            const data = await AuthServices.authRegisterViaEmail(obj)
            console.log(data);
            if (data.responseCode == 200) {

                localStorage.setItem('authToken', JSON.stringify(data?.data?.token))
                localStorage.setItem('user', JSON.stringify(sendData))
                let userInfo = localStorage.getItem('user')
                setUser(data?.data)
                handleClose()

            }
            if (data?.responseCode == 206) {
                setOtpEnable(true)
            }



        } catch (error) {
            JSON.Stringyfy(error)
        } finally {

        }
    }
    const SubmitOTP = async (sendData, result) => {
        console.log(sendData, 'data');
        try {
            let obj = {

              ...userObj,
              otp:otp
            }
            
            const data = await AuthServices.authRegisterViaEmail(obj)
            console.log(data);
            if (data.responseCode == 206) {

                setOtpToken(data?.otp_token)
                Register(sendData,data?.data?.otp_token)

            }
            



        } catch (error) {
           
        } finally {

        }
    }
    const Register = async (sendData, token) => {
        console.log(sendData, 'data');
        try {
            let obj = {

              ...userObj,
              otp_token:token
            }
            
            const data = await AuthServices.authRegisterViaEmail(obj)
            console.log(data);
            if (data.responseCode == 200) {

                localStorage.setItem('authToken', JSON.stringify(data?.data?.token))
                localStorage.setItem('user', JSON.stringify(data?.data))
                let userInfo = localStorage.getItem('user')
                setUser(data?.data)
                handleClose()

               

            }
            



        } catch (error) {
           
        } finally {

        }
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            sx={{ '.MuiDialog-container': { height: 'auto !important' } }}


            slotProps={{
                backdrop: {
                    sx: {
                        backdropFilter: "blur(8px)",
                        backgroundColor: "transparent",
                    }

                },


            }}
            PaperProps={{
                sx: {
                    borderRadius: '15px',
                    backgroundColor: '#0F172A',

                    boxShadow: 'none'
                },
            }}

        >
            <Grid container sx={{ p: 3 }}>
                <Grid item lg={5} md={5} sm={12} xs={12} pr={3} sx={{ backgroundColor: '#0F172A', color: 'white', display: 'flex', alignItems: 'center' }}>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: currentState == "login" ? "flex-end" : "center",
                        }}
                    >

                        {(screen == 'login' || currentState == 'login') && currentState != 'register' &&
                            <form onSubmit={handleSubmit(handleLogin)}>
                                <Typography className='heading-font' variant="h5" sx={{ fontWeight: 700, textAlign: 'center', }}>
                                    Welcome
                                </Typography>
                                <Typography variant="h6" mb={2} sx={{ textAlign: 'center', fontSize: '14px' }}>
                                    We are glad to see you back with us
                                </Typography>
                                <TextField
                                    fullWidth
                                    placeholder='Email'
                                    variant="outlined"
                                    type='email'
                                    size='small'
                                    {...register('login', { required: 'Email is required' })}
                                    error={!!errors.login}
                                    helperText={errors.login ? errors.login.message : ''}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Box component={'img'} src={Images.emailIcon} width={'17px'}></Box>

                                            </InputAdornment>
                                        ),
                                        style: {
                                            color: '#0F172A',
                                            fontSize: '16px',
                                            borderRadius: 8, // Rounded corners
                                        },
                                    }}
                                    sx={{
                                        mb: 2,
                                        backgroundColor: '#1E1E1E', // Dark background color
                                        '& .MuiOutlinedInput-root': {
                                            backgroundColor: 'white',
                                            '& fieldset': { borderColor: 'transparent' }, // Blue border
                                            '&:hover fieldset': { borderColor: 'transparent' },
                                            '&.Mui-focused fieldset': { borderColor: 'transparent' },
                                        },
                                        '& .MuiInputLabel-root': { color: 'black' },
                                        '& .MuiInputLabel-root.Mui-focused': { color: 'black' },
                                    }}
                                />

                                <TextField

                                    fullWidth
                                    placeholder='Password'
                                    size='small'
                                    variant="outlined"
                                    type={showPasswordLogin ? 'text' : 'password'}
                                    {...register('password', { required: 'Password is required' })}
                                    error={!!errors.password}
                                    helperText={errors.password ? errors.password.message : ''}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Box component={'img'} src={Images.passwordIcon} width={'17px'}></Box>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPasswordLogin(!showPasswordLogin)} edge="end">
                                                    {showPasswordLogin ? <VisibilityIcon sx={{ color: '#0F172A', fontSize: '20px' }} /> : <VisibilityOffIcon sx={{ color: '#0F172A', fontSize: '20px' }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        style: {
                                            color: '#0F172A',
                                            fontSize: '16px',
                                            borderRadius: 8, // Rounded corners
                                        },
                                    }}
                                    sx={{
                                        mb: 2,
                                        backgroundColor: '#1E1E1E', // Dark background color
                                        '& .MuiOutlinedInput-root': {
                                            backgroundColor: 'white',
                                            '& fieldset': { borderColor: 'transparent' }, // Blue border
                                            '&:hover fieldset': { borderColor: 'transparent' },
                                            '&.Mui-focused fieldset': { borderColor: 'transparent' },
                                        },
                                        '& .MuiInputLabel-root': { color: 'black' },
                                        '& .MuiInputLabel-root.Mui-focused': { color: 'black' },
                                    }}
                                />

                                {error && <span style={{ color: '#d32f2f', fontSize: '12px', marginBottom: '10px' }}>{error}</span>}
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#0EA5EA',
                                        color: '#22272d',
                                        padding: '6px',
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        borderRadius: 2,
                                        textTransform: 'capitalize',
                                        mt: 1,
                                        '&:hover': { backgroundColor: '#0EA5EA' },
                                    }}
                                >
                                    Login
                                </Button>
                            </form>}

                        {(screen == 'register' || currentState == 'register') && currentState != 'login' && !otpEnable && <form onSubmit={handleSubmit2(handleRegister)}>
                            {/* Name Field */}
                            <Typography className='heading-font' variant="h5" mb={2} sx={{ fontWeight: 'bold', textAlign: 'center', fontFamily: "Plus Jakarta Sans", }}>
                                Signup now
                            </Typography>
                            <TextField
                                fullWidth
                                placeholder="Full Name"
                                variant="outlined"
                                size='small'
                                {...register2('name', { required: 'Name is required' })}
                                error={!!errors2.name}
                                helperText={errors2.name ? errors2.name.message : ''}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Box component={'img'} src={Images.emailIcon} width={'17px'}></Box>

                                        </InputAdornment>
                                    ),
                                    style: {
                                        color: '#0F172A',
                                        fontSize: '16px',
                                        borderRadius: 8, // Rounded corners
                                    },
                                }}
                                sx={{
                                    mb: 2,
                                    backgroundColor: '#1E1E1E', // Dark background color
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: 'white',
                                        '& fieldset': { borderColor: 'transparent' }, // Blue border
                                        '&:hover fieldset': { borderColor: 'transparent' },
                                        '&.Mui-focused fieldset': { borderColor: 'transparent' },
                                    },
                                    '& .MuiInputLabel-root': { color: 'black' },
                                    '& .MuiInputLabel-root.Mui-focused': { color: 'black' },
                                }}
                            />

                            {/* Email Field */}
                            <TextField
                                fullWidth
                                placeholder="Email"
                                variant="outlined"
                                size='small'
                                type="email"
                                {...register2('email', { required: 'Email is required' })}
                                error={!!errors2.email}
                                helperText={errors2.email ? errors2.email.message : ''}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Box component={'img'} src={Images.emailIcon} width={'17px'}></Box>

                                        </InputAdornment>
                                    ),
                                    style: {
                                        color: '#0F172A',
                                        fontSize: '16px',
                                        borderRadius: 8, // Rounded corners
                                    },
                                }}
                                sx={{
                                    mb: 2,
                                    backgroundColor: '#1E1E1E', // Dark background color
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: 'white',
                                        '& fieldset': { borderColor: 'transparent' }, // Blue border
                                        '&:hover fieldset': { borderColor: 'transparent' },
                                        '&.Mui-focused fieldset': { borderColor: 'transparent' },
                                    },
                                    '& .MuiInputLabel-root': { color: 'black' },
                                    '& .MuiInputLabel-root.Mui-focused': { color: 'black' },
                                }}
                            />



                            {/* Password Field */}
                            <TextField
                                fullWidth
                                placeholder="Password"
                                variant="outlined"
                                size='small'
                                type={showPassword ? "text" : "password"}
                                {...register2('password', { required: 'Password is required' })}
                                error={!!errors2.password}
                                helperText={errors2.password ? errors2.password.message : ''}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Box component={'img'} src={Images.passwordIcon} width={'17px'}></Box>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                {showPasswordLogin ? <VisibilityIcon sx={{ color: '#0F172A', fontSize: '20px' }} /> : <VisibilityOffIcon sx={{ color: '#0F172A', fontSize: '20px' }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    style: {
                                        color: '#0F172A',
                                        fontSize: '16px',
                                        borderRadius: 8, // Rounded corners
                                    },
                                }}
                                sx={{
                                    mb: 2,
                                    backgroundColor: '#1E1E1E', // Dark background color
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: 'white',
                                        '& fieldset': { borderColor: 'transparent' }, // Blue border
                                        '&:hover fieldset': { borderColor: 'transparent' },
                                        '&.Mui-focused fieldset': { borderColor: 'transparent' },
                                    },
                                    '& .MuiInputLabel-root': { color: 'black' },
                                    '& .MuiInputLabel-root.Mui-focused': { color: 'black' },
                                }}
                            />

                            {/* Confirm Password Field */}
                            <TextField
                                fullWidth
                                placeholder="Confirm Password"
                                size='small'
                                variant="outlined"
                                type={showPassword2 ? "text" : "password"}
                                {...register2('confirmPassword', {
                                    required: 'Confirm Password is required',
                                    validate: (value) =>
                                        value === getValues2('password') || 'Passwords do not match',
                                })}
                                error={!!errors2.confirmPassword}
                                helperText={errors2.confirmPassword ? errors2.confirmPassword.message : ''}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Box component={'img'} src={Images.passwordIcon} width={'17px'}></Box>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword2(!showPassword2)} edge="end">
                                                {showPasswordLogin ? <VisibilityIcon sx={{ color: '#0F172A', fontSize: '20px' }} /> : <VisibilityOffIcon sx={{ color: '#0F172A', fontSize: '20px' }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    style: {
                                        color: '#0F172A',
                                        fontSize: '16px',
                                        borderRadius: 8, // Rounded corners
                                    },
                                }}
                                sx={{
                                    mb: 2,
                                    backgroundColor: '#1E1E1E', // Dark background color
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: 'white',
                                        '& fieldset': { borderColor: 'transparent' }, // Blue border
                                        '&:hover fieldset': { borderColor: 'transparent' },
                                        '&.Mui-focused fieldset': { borderColor: 'transparent' },
                                    },
                                    '& .MuiInputLabel-root': { color: 'black' },
                                    '& .MuiInputLabel-root.Mui-focused': { color: 'black' },
                                }}
                            />


                            {/* Submit Button */}
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#0EA5EA',
                                    color: '#22272d',
                                    padding: '6px',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    borderRadius: 2,
                                    textTransform: 'capitalize',
                                    mt: 1,

                                    '&:hover': { backgroundColor: '#0EA5EA' },
                                }}
                            >
                                REGISTER
                            </Button>
                        </form>}
                        {otpEnable && <>
                            <Typography className='heading-font' variant="h5" mb={2} sx={{ fontWeight: 600, textAlign: 'center', fontFamily: "Plus Jakarta Sans", }}>
                                Enter OTP
                            </Typography>
                            <div className="otp-container">
                                <OTPInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    renderSeparator={<span className="separator">-</span>}
                                    renderInput={(props) => <input className="otp-input" {...props} />}
                                />

                            </div>  <Button
                                fullWidth
                         
                                variant="contained"
                                disabled={otp?.length < 4}
                                sx={{
                                    backgroundColor: '#0EA5EA',
                                    color: '#22272d',
                                    padding: '6px',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    borderRadius: 2,
                                    textTransform: 'capitalize',
                                    mt: 3,

                                    '&:hover': { backgroundColor: '#0EA5EA' },
                                }}
                                onClick={()=> SubmitOTP()}
                            >
                                Submit
                            </Button> </>}
                        {!otpEnable && currentState == 'login' && <Box display="flex" alignItems="center" justifyContent="center" sx={{ my: 3 }}>
                            <Box sx={{ height: '1px', backgroundColor: '#888', flex: 1, mr: 2 }}></Box>
                            <Typography variant="body2" sx={{ color: 'gray' }}>Or Continue with</Typography>
                            <Box sx={{ height: '1px', backgroundColor: '#888', flex: 1, ml: 2 }}></Box>
                        </Box>}

                        {!otpEnable && currentState == 'login' && <Box spacing={2} display={'flex'} justifyContent="center" alignItems="center" gap={4} sx={{ mt: 2 }}>
                            <Button
                                onClick={() => {
                                    handleClose();
                                    handleGoogleLogin();
                                }}

                                variant="contained"
                                sx={{
                                    backgroundColor: 'transparent',
                                    color: 'white',
                                    padding: '8px 8px',
                                    border: '1px solid #0EA5EA !important',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    textTransform: 'capitalize',
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontSize: '12px'
                                }}
                            >
                                <Box component={'img'} src={Images.googleIcon} width={'30px'} />

                            </Button>
                            <Button
                                // onClick={() => {
                                //     handleClose();
                                //     handleGoogleLogin();
                                // }}

                                variant="contained"
                                sx={{
                                    backgroundColor: 'transparent',
                                    color: 'white',
                                    padding: '8px 8px',
                                    border: '1px solid #0EA5EA !important',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    textTransform: 'capitalize',
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontSize: '12px'
                                }}
                            >
                                <Box component={'img'} src={Images.instaIcon} width={'30px'} />

                            </Button>
                        </Box>}
                        {(!otpEnable && currentState == 'register') ? <Typography variant="body2" sx={{ color: 'white', textAlign: 'center', mt: 2 }}>Already have an account? <span style={{ fontWeight: 'bold', cursor: 'pointer', textDecoration: 'underline', color: '#0EA5EA' }} onClick={() => setCurrentState('login')}>Login</span></Typography> : !otpEnable && <Typography variant="body2" sx={{ color: 'white', textAlign: 'center', mt: 2 }}>Don't have an account? <span className='heading-font' style={{ fontWeight: 'bold', cursor: 'pointer', textDecoration: 'underline', color: '#0EA5EA' }} onClick={() => setCurrentState('register')}>Signup now</span></Typography>}

                    </Box>

                </Grid>

                <Grid item xs={7} sx={{ backgroundColor: '#0F172A', display: { lg: 'flex', md: 'flex', sm: 'none', xs: 'none' } }}>
                    <Box component={'img'} src={Images.modalPic} width={'100%'} >

                    </Box>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default SignInModal;
